:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 40vh;
    /* Colors */
    --text-color: #313638;
    --body-bg: #fff;
    --main-color: #fff;
    --accent-color: #8F9090;
    --effect-color: #E8440B;
    --header-text-color: #fff;
    --header-bg-color: #fff;
    --footer-bg-color: #222;
    --footer-text-color: #fff;
    --menu-bg-color: #222;
    --menu-text-color: #606060;
    --menu-text-hover-color: var(--effect-color);
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: var(--effect-color);
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Lato, sans-serif;
    --alternative-font-family: Roboto, sans-serif;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/baguetteBox.min.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Roboto.css";
@import "/fontmodules/Lato.css";


/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    background: var(--body-bg);
    color: var(--text-color);
}

.homepage main {
    background: var(--main-color);
}

header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    z-index:300;
}


footer {
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
    width: 100%;
}

.footer-wrapper {
    background: #222;
}

.footer-contactinfo {
    margin-top: 2em;
}

.footer-logo {
    width: 80%;
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo a {
    padding: 1rem;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    /*padding: 0.5em;*/
    width: 100%;
    margin-top: 140px;
}

.homepage .container {
    margin-top: 0;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

p {
    margin-top: auto;
}

figure {
    margin: 0;
}
        

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    font-family: var(--alternative-font-family);
    color: var(--effect-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
    font-family: var(--alternative-font-family);
    transition: 0.5s ease-in 0s;
}

.menu.menu li.active a, .menu.menu li a:hover {
    color: var(--menu-text-hover-color);
    /*border-bottom: 2px solid var(--effect-color);*/
}

.menu .dropdown {
    background: var(--header-bg-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin-bottom: 0;
}

.start-slider{
    position:relative;
}

.textslider {
    position:absolute;
    top:35vh;
    left:20%;
}

.textslider .container {
    text-align:right;
}

.textslider-inner {
    text-align:right;
    max-width:400px;
    width:100%;
    margin:0;
    color: #fff;;
    font-size: calc(1 * var(--scale-factor) - 0.5rem);
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

.textslider-inner a {
    display: inline-block;
    border-bottom: #eee solid 1px;
    padding-bottom:1em;
}

.textslider-inner h2, .textslider-inner h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color: #fff;
}

.slick-substitute > div {
    height:100%;
    min-height: 70vh;
    background-size: cover;
    background-position: center center;
}

.slick-substitute {
    overflow:hidden;
}

.slider-img {
  animation: kenburns 100s infinite linear;
}

.slick-next {
    right: 11px;
}

.slick-prev {
    left: 11px;
    z-index: 10;
}

/********* scroll *********/
.scrolldown {
    position:absolute;
    left:0;
    right:0;
    bottom:30px;
    z-index:100;
    width:100%;
    color:red;
}

.scrolldown .container {
    text-align:center;
}

.scrolldown .container a {
    display:inline-block;
}

.scrolldown .container a svg.fa-icon {
    height:auto;
    width:6em;  
}

.scrolldown .container a svg.fa-icon:hover {
    fill: var(--effect-color);
    transition: 0.5s ease-in 0s;
}

@keyframes kenburns {
  0% {
    transform: scale(1) rotate(0deg)
  }
  50% {
    transform: scale(1.4)
  }
}

/*-------------------------------------------------------------
Sidscecifik css
--------------------------------------------------------------*/

.intro {
    background: url('/theme/intendit/graphics/introbg.jpg'); 
    min-height: 400px;   
    border-top: 1px solid #f5f5f5;
    margin: 0;
    color: #fff;
    display: flex;
}

.intro-inner {
    margin: auto;
    text-align: center;
    max-width: 90%;
}

.socmedia {
    min-height: 200px;
    background: var(--body-bg);
}

/*Facebook album*/
.album-bild {
    position:relative;
}
.album-bild img {
    display:block;
}
.info { 
    opacity:0;  
    position:absolute; 
    width:100%; 
    height:100%; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:1; 
    padding: 10px; 
    font-size:0.9em; 
    line-height:1.4em; 
    text-align:right; 
    color:#e4e4e4;  
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.9) 100%); 
}
.info img { 
    display: inline-block;
    height:17px; 
    width:auto; 
    margin:0 0 0 5px; 
    vertical-align:top !important; 
    fill:#e4e4e4;
}
.album-bild:hover .info {
    opacity:1; 
}


.teaser {
    font-weight: bold;
    padding-bottom: 2rem;
}

/*-------------------------------------------------------------
BOXAR
--------------------------------------------------------------*/
/*.boxes {
    background: url('/theme/intendit/graphics/boxbg.jpg'); 
} */

/*ikoner*/
.fa-icon {
    height:20px; 
    width: auto; 
    vertical-align:middle !important; 
}
.round {
    background: rgba(231, 68, 12, 0.9); 
    border-radius: 50%; 
    display: inline-block; 
    height: 80px; width: 80px; 
    margin: 0 10px 10px 0; 
}
.round .fa-icon {
    fill:#fff; 
    padding: 8px 0; 
    height:80px; 
    width: auto; 
    display:block; 
    margin: 0 auto; 
}

.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

.boxwrapper {
    min-height: 30vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: #222;
    align-items: flex-start;
}

.boxwrapper:nth-child(1), .boxwrapper:nth-child(3)  {
    background: #f5f5f5;
}

.homepage .boxwrapper, .tjanster .boxwrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption  {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before  {
    content: '';
    transition: transform .3s ease-in 0s;
}


.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}

.boxwrapper:hover:after {
    background: rgba(0,0,0,0.1);
    transition: background .3s ease-in 0s;
}

.box-inner {
    padding: var(--padding);
    text-align: center;
}



/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
   /* IE fix */ 
    min-height: 1%;


}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltforms-row label {    
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

.formular .boltforms-row label {
    color: #000;

}
.boltforms-row {
    color: #000000;
}


.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 8vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: var(--effect-color);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

.boltforms-row button:hover {
    background: var(--accent-color);
    color: #fff;
    transition: 0.5s ease-in 0s;
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
    background: #222;
}

.intenditfooter a {
    text-decoration: none;
}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
    
      .footer-logo {
        width: 60%;
        margin: 0 auto;
    }
    
    .footer-contactform {
        max-width: 80%;
        margin: 0 auto;
    }
    
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    

}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
    .homepage article {
        column-count: 1;
    }
    
        .movie-link {
        max-width: 350px;
        margin: 0 auto;
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: #fff;
    }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 2px solid var(--effect-color);
    }
    
    .menu.menu li.active a {
        background: #eee;
    }
    

    
    nav {
        margin-top: -50px !important;
    }
}


.movie-link{
    width: 500px;
    height: 300px;
    background-size: cover;
}
.movie-link svg{
    fill: white;
}

.movie-link .fa-icon {height:60px; width: auto; vertical-align:middle !important; }
